.e-loading-shading {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .3);
  }
  
  .e-loading-icon {
    position: fixed;
    font-size: 25px;
    top: calc(55%);
    left: calc(55%);  
    z-index: 1060;  
  }