@import '../../styles/variables.scss';

.e-noResultsFound{
    align-items: center;
    color: $neutral;
    display: flex;
    font-size: 17px;
    justify-content: center;
    padding: 12px 16px;
    //width: 360px;
    margin-top: 20px;
}