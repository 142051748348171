textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 0 0 #ddd;
}

body {
  font-family: 'Roboto', sans-serif;
}

.has-error {
  input, textarea, select, .input-group-text {
    border-color: $dark-red !important;
  }
}

.form-text {
  display: flex;
  justify-content: flex-start;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.content-container {
  margin-top: $content-height;
  margin-bottom: $content-height;;
}

.list-item {
  border-top: 1px solid $light-grey;
  padding: 10px 5px;
  align-items: center;
}

.nav-item {
  a {
    color: $dark-blue;
  }
}

.content-title {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 1rem;
}

th {
  font-weight: 300;
}

.section-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}

.form-control::-webkit-input-placeholder { color: #EDEDED; font-size: 16px; font-weight: 300}  /* WebKit, Blink, Edge */
.form-control:-moz-placeholder { color: #EDEDED; }  /* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder { color: #EDEDED; }  /* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder { color: #EDEDED; }  /* Internet Explorer 10-11 */
.form-control:-ms-input-placeholder { color: #EDEDED; }
//input::-webkit-input-placeholder { color: #EDEDED; font-size: 16px; font-weight: 400}
//
//
