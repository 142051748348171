@import '../../styles/variables.scss';

$item-placement-marker-height: 2px;

.listView-column {
    color: $neutral;
    font-weight: 500;
  &.is-active {
       color: #545e63 !important;
       font-weight: 600;
   }

  &.is-clickable{
       cursor: pointer;
   }

  &:last-child {
       margin-right: 0;
   }
  &:hover{
       color: black;
       font-weight: 600;
   }

  .ct-icon-tiny{
      font-size: 20px;
      vertical-align: bottom;
  }

  &.action-items{
    text-align: right;
  }

}

.listView-heading{
  border-top: 2px solid #dee2e6;
}

.items-list-grid {
  table {
    thead {
      th {
        border-bottom: none;
      }
    }
    tbody
    {
      tr:first-child {
        td {
          border-top: 2px solid #dee2e6;
        }
      }
      tr{
        &:hover{
          background-color: $brand-lightest;
        }
      }
    }
    .small-buttons{
      display: flex;
      justify-content: flex-end;
      button{
        padding: .1rem .4rem .3rem .4rem;
      }
    }
  }
}












