.content-title {
  span {
    //font-weight: 400;
  }
}

.c-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-user-to-hotel {
  &.modal-dialog {
    max-width: 372px;
    margin: 1rem auto;
  }
  .list-assigned-users.card-body{
    padding: 0;
  }
}

.hotel-user-item{
  padding: 13px 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

@media (min-width: 576px) {
  .add-user-to-hotel.modal-dialog {
    max-width: 410px;
    margin: 1.75rem auto;
    .react-autosuggest__input{
      width: 315px;
    }
  }

}