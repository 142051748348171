.logo{
  height: 64px;
  width: auto;
}

.header{
  background-color: $brand-lightest;
}


.primary-heading{
  background-color: $blue;
}

.actions{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row;
}

.actions__link{
  margin-right: $s-size;
  border-radius: 4px;
  a{
    color: white;
    &:hover{
      color: $brand-lighter;
    }
  }
  &.active{
    background-color: rgba(255,255,255,0.1);
  }
}

.current-hotel{
  font-family: 'Crimson Text', serif;
  font-size: 22px;
  position: absolute;
  top: 30%;
  left: 44%;
}

.hotel-icon{
  margin-bottom: 6px;
}

.hotel-name{
  margin-left: 5px;
}

.dropdown-item{
  a{
    text-decoration: none;
  }
  &.active{
    background-color: $neutral-light;
  }
}

@media(max-width: $ipad-breakpoint){
  .current-hotel{
    left: 38%;
  }
}
