.forecast-header {
  align-items: center;
  margin-bottom: 1rem;
}

.forecast-content{
  height: calc(100vh - 350px);
  overflow-y: auto;
  overflow-x: auto;
}

.forecast-heading {
  th.bg-diagonal-lines {
    text-align: center;
    vertical-align: middle;
  }
}

.date-selector {
  display: flex;
  flex-direction: column;
}

#yearSelector {
  width: 90px;
}

.year-comparison {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.forecast__dates {
  display: flex;
}

.forecast-range-date {
  margin-left: 1rem;
}

@media(max-width: $tablet-breakpoint) {
  .year-comparison {
    align-items: flex-start;
  }

  .forecast__dates {
    flex-direction: column;
  }

  .forecast-range-date {
    margin-left: 0;
  }
}