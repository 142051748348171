.loading-dialog {
  .modal-body{
    align-items: center;
    display: flex;
    .dialog-message{
      font-size: 22px;
      font-weight: 300;
    }
  }

}