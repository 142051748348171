// Colors
$dark-grey: #333;
$grey: #666;
$blue: #0f0f9e;
$dark-blue: #364051;
$off-white: #f7f7f7;
$dark-red: #dc3545;
$light-red: #ff0000;
$light-grey: #dee2e6;
$brand-lightest: #E0F5FF;
$brand-lighter: #81d4fa;
$brand-light: #29b6f6;
$brand: #03a9f4;
$input-placeholder-color: blue;
$comparative-color: #7f7f7f;

$brand-accent: #2d85c0;
$brand-dark: #37474f;
$black: #212529;

$neutral-lightest: #f1f1f1;
$neutral-lighter: #eceff1;
$neutral-light: #90a4ae;
$neutral-dark: #545e63;

$warning: #e95350;
$warning-light: fade($warning, 15%);
$success: #5cb85c;
$info: #f0ad4e;
$normal: #ffc107;
$complete: #5cb85c;

$brand-compliment: #19bb9b;

$offWhite: #fbfbfb;
$white: #ffffff;

$content-height: 2rem;
// Font Size
$font-size-large: 1.8rem;
$font-size-small: 1.4rem;
// Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 1024px;
$tablet-breakpoint: 768px;
$ipad-breakpoint: 992px;