

.user-actions{
  display: flex;
  justify-content: flex-end;
  button{
    margin-left: .6rem;
  }
}

.list{
  height: calc(100vh - 360px);
  //overflow-y: auto;
  //overflow-x: hidden;
}