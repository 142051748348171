.table{
  th, td{
    padding: 0.5rem 0.7rem;
    white-space:nowrap;
  }

  tbody{
    font-weight: 300;
  }

  thead{
    th{
      font-weight: 500;
    }
  }

  .table-cell-corner-left{
    border-top: none;
    border-left: none;
  }
  tr{
    &.comparative_forecast{
      color: lighten($comparative-color, 25%);
    }
  }

}

