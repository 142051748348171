.nav.nav-tabs{
  margin-bottom: 1.2rem;
}

.tab-pane-title{
  margin-bottom: 1rem;
}

.tab-pane-header{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  h4{
    margin-bottom: 0;
  }
  button{
    align-items: center;
    display: flex;
  }
}