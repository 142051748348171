.box-layout {
  align-items: center;
  background: url('/images/bg.jpg');
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

.box-layout__box {
  background: fade-out(white, .15);
  border-radius: 3px;
  margin: 0 1.5rem;
  padding: $s-size;
  text-align: center;
  width: 25rem;
}

.box-layout__form {
  .alert {
    padding: 6px 1.2rem;
  }
}

.box-layout__title {
  margin: 0 0 $m-size 0;
  line-height: 1;
  hr {
    margin-top: 10px;
    margin-bottom: 0px;
    clear: both;
    border: 0;
    height: 1px;
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
  }
  h1 {
    margin: 0;
  }
}
