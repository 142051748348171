.price-log {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.price-log__simbol {
  margin-right: 5px;
  margin-bottom: 0;
}

.price-log__input{
  width: 70px;
}