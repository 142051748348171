// Brand Theme
$brand-lightest: #FDF8f4;
$brand-lighter: #FFE9A4;
$brand-light: #FDC101;
$brand: #f57c00;
$brand-accent: #e12c0c;
$brand-dark: #8d1f0b;
$primary-color: #f57c00;
$secondary-color: #79828a;
$btn-default-color: #FDF8f4;
$dashboard-color:#FDF8f4;
$sidebar-icon-active: #f57c00;
$link-hover: #FFE9A4;
$link-text-color: #303030;
$menu-font-size: 15px;

$neutral-lightest: #f1f1f1;
$neutral-lighter: #eceff1;
$neutral-light: #90a4ae;
$neutral: #90a4ae;
$neutral-dark: #545e63;

$placeholder-bg-color: #aca9a9;

$warning: #e95350;
$warning-light: fade($warning, 15%);
$success: #5cb85c;
$info: #f0ad4e;
$normal: #ffc107;
$complete: #5cb85c;

$brand-compliment: #19bb9b;

$offWhite: #fbfbfb;
$white: #ffffff;


// Main Layout
$header-height: 48px;
$heading-height: 132px;
$heading-phone-height: 80px;
$secondary-heading-height: 96px;
$footer-height: 48px;

/*$navigation-width: 256px;*/
$navigation-width: 0;

$default-margin-width: 8px;
$default-padding-width: 8px;


$font-size-normal: 16px;

$rhythm-giga: 6 !default;
$rhythm-mega: 5 !default;
$rhythm-kilo: 3.5 !default;
$rhythm-alpha: 2.25 !default;
$rhythm-beta: 1.75 !default;
$rhythm-delta: 1.5 !default;
$rhythm-gamma: 1.25 !default;
$rhythm-epsilon: 1 !default;
$rhythm-zeta: 0.875 !default;
$rhythm-milli: 0.75 !default;
$rhythm-micro: 0.625 !default;

$rhythm: (
        giga: $rhythm-giga,
        mega: $rhythm-mega,
        kilo: $rhythm-kilo,

        alpha: $rhythm-alpha,
        beta: $rhythm-beta,
        delta: $rhythm-delta,
        gamma: $rhythm-gamma,
        epsilon: $rhythm-epsilon,

        zeta: $rhythm-zeta,
        milli: $rhythm-milli,
        micro: $rhythm-micro
) !default;
@function rhythm($size, $unit: rem) {
  @return map-get($rhythm, $size) + $unit;
}

$spacing-line-height-ratio: 1.2 !default;

$spacing-none: 0 !default;
$spacing-extra-small: 0.25 !default;
$spacing-small: 0.5 !default;
$spacing-medium: 1 !default;
$spacing-large: 2 !default;
$spacing-extra-large: 4 !default;
$spacing-extra-extra-large: 8 !default;
$spacing-extra-extra-extra-large: 16 !default;

$spacing: (
        none: $spacing-none,
        extra_small: $spacing-extra-small,
        small: $spacing-small,
        medium: $spacing-medium,
        large: $spacing-large,
        extra_large: $spacing-extra-large,
        extra_extra_large: $spacing-extra-extra-large,
        extra_extra_extra_large: $spacing-extra-extra-extra-large
) !default;
@function spacing($size, $unit: rem) {
  @return map-get($spacing, $size) + $unit;
}

$font-weight-light: 100;
$font-weight-regular: 300;
$font-weight-medium: 500;
$font-weight-bold: 700;

$weight: (
        light: $font-weight-light,
        regular: $font-weight-regular,
        medium: $font-weight-medium,
        bold: $font-weight-bold
) !default;
@function weight($size) {
  @return map-get($weight, $size);
}

$spacing-none: 0 !default;
$spacing-extra-small: 0.25 !default;
$spacing-small: 0.5 !default;
$spacing-medium: 1 !default;
$spacing-large: 2 !default;
$spacing-extra-large: 4 !default;
$spacing-extra-extra-large: 8 !default;
$spacing-extra-extra-extra-large: 16 !default;

$spacing: (
        none: $spacing-none,
        extra_small: $spacing-extra-small,
        small: $spacing-small,
        medium: $spacing-medium,
        large: $spacing-large,
        extra_large: $spacing-extra-large,
        extra_extra_large: $spacing-extra-extra-large,
        extra_extra_extra_large: $spacing-extra-extra-extra-large
) !default;
@function spacing($size, $unit: rem) {
  @return map-get($spacing, $size) + $unit;
}

$font-weight-light: 100;
$font-weight-thin: 200;
$font-weight-regular: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-semibold: 500;

$font-size-normal: 16px;

$screen-tablet-width: 1024px;
$screen-tablet-height: 768px;
$screen-phone-width: 480px;
$screen-phone-height: 568px;
$screen-default-breakpoint: 576px;

$box-shadow: 2.1px 2.1px 0 rgba(0, 0, 0, 0.12) !default;
$shadow-text: 2.1px 2.1px 0 rgba(0, 0, 0, 0.08) !default;

$shadow: (
        box: $box-shadow,
        text: $shadow-text
) !default;
@function shadow($size) {
  @return map-get($shadow, $size);
}

// Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 1024px;
$tablet-breakpoint: 768px;
$ipad-breakpoint: 992px;
